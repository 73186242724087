// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-announcements-gofloaters-debuts-in-hyderabad-js": () => import("./../../../src/pages/announcements/gofloaters-debuts-in-hyderabad.js" /* webpackChunkName: "component---src-pages-announcements-gofloaters-debuts-in-hyderabad-js" */),
  "component---src-pages-announcements-gofloaters-on-an-expansion-drive-js": () => import("./../../../src/pages/announcements/gofloaters-on-an-expansion-drive.js" /* webpackChunkName: "component---src-pages-announcements-gofloaters-on-an-expansion-drive-js" */),
  "component---src-pages-announcements-gofloaters-on-demand-workspace-app-expands-to-kochi-js": () => import("./../../../src/pages/announcements/gofloaters-on-demand-workspace-app-expands-to-kochi.js" /* webpackChunkName: "component---src-pages-announcements-gofloaters-on-demand-workspace-app-expands-to-kochi-js" */),
  "component---src-pages-announcements-gofloaters-on-demand-workspace-app-expands-to-pune-js": () => import("./../../../src/pages/announcements/gofloaters-on-demand-workspace-app-expands-to-pune.js" /* webpackChunkName: "component---src-pages-announcements-gofloaters-on-demand-workspace-app-expands-to-pune-js" */),
  "component---src-pages-app-success-page-js": () => import("./../../../src/pages/app-success-page.js" /* webpackChunkName: "component---src-pages-app-success-page-js" */),
  "component---src-pages-blog-13-wonderful-best-work-from-home-jobs-js": () => import("./../../../src/pages/blog/13-wonderful-best-work-from-home-jobs.js" /* webpackChunkName: "component---src-pages-blog-13-wonderful-best-work-from-home-jobs-js" */),
  "component---src-pages-blog-7-digital-marketing-trends-in-2021-js": () => import("./../../../src/pages/blog/7-digital-marketing-trends-in-2021.js" /* webpackChunkName: "component---src-pages-blog-7-digital-marketing-trends-in-2021-js" */),
  "component---src-pages-blog-aceing-sales-with-ondemand-meeting-spaces-js": () => import("./../../../src/pages/blog/aceing-sales-with-ondemand-meeting-spaces.js" /* webpackChunkName: "component---src-pages-blog-aceing-sales-with-ondemand-meeting-spaces-js" */),
  "component---src-pages-blog-how-gofloaters-helped-the-largest-sports-retailer-to-hire-the-best-talent-js": () => import("./../../../src/pages/blog/how-gofloaters-helped-the-largest-sports-retailer-to-hire-the-best-talent.js" /* webpackChunkName: "component---src-pages-blog-how-gofloaters-helped-the-largest-sports-retailer-to-hire-the-best-talent-js" */),
  "component---src-pages-blog-how-notionpress-increased-team-bonding-during-the-pandemic-js": () => import("./../../../src/pages/blog/how-notionpress-increased-team-bonding-during-the-pandemic.js" /* webpackChunkName: "component---src-pages-blog-how-notionpress-increased-team-bonding-during-the-pandemic-js" */),
  "component---src-pages-blog-hybrid-work-journey-of-saas-unicorn-js": () => import("./../../../src/pages/blog/hybrid-work-journey-of-saas-unicorn.js" /* webpackChunkName: "component---src-pages-blog-hybrid-work-journey-of-saas-unicorn-js" */),
  "component---src-pages-blog-practical-guide-to-gain-new-leads-from-quora-js": () => import("./../../../src/pages/blog/practical-guide-to-gain-new-leads-from-quora.js" /* webpackChunkName: "component---src-pages-blog-practical-guide-to-gain-new-leads-from-quora-js" */),
  "component---src-pages-blog-top-5-hr-trends-in-2022-js": () => import("./../../../src/pages/blog/top-5-hr-trends-in-2022.js" /* webpackChunkName: "component---src-pages-blog-top-5-hr-trends-in-2022-js" */),
  "component---src-pages-blog-why-do-you-need-virtual-office-in-2022-js": () => import("./../../../src/pages/blog/why-do-you-need-virtual-office-in-2022.js" /* webpackChunkName: "component---src-pages-blog-why-do-you-need-virtual-office-in-2022-js" */),
  "component---src-pages-booking-detail-js": () => import("./../../../src/pages/booking-detail.js" /* webpackChunkName: "component---src-pages-booking-detail-js" */),
  "component---src-pages-booking-js": () => import("./../../../src/pages/booking.js" /* webpackChunkName: "component---src-pages-booking-js" */),
  "component---src-pages-bookings-js": () => import("./../../../src/pages/bookings.js" /* webpackChunkName: "component---src-pages-bookings-js" */),
  "component---src-pages-cafe-coworking-js": () => import("./../../../src/pages/cafe-coworking.js" /* webpackChunkName: "component---src-pages-cafe-coworking-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-covid-safety-pledge-js": () => import("./../../../src/pages/covid-safety-pledge.js" /* webpackChunkName: "component---src-pages-covid-safety-pledge-js" */),
  "component---src-pages-coworking-cafes-2-js": () => import("./../../../src/pages/coworking-cafes-2.js" /* webpackChunkName: "component---src-pages-coworking-cafes-2-js" */),
  "component---src-pages-coworking-spaces-in-bangalore-js": () => import("./../../../src/pages/coworking-spaces-in-bangalore.js" /* webpackChunkName: "component---src-pages-coworking-spaces-in-bangalore-js" */),
  "component---src-pages-coworking-spaces-js": () => import("./../../../src/pages/coworking-spaces.js" /* webpackChunkName: "component---src-pages-coworking-spaces-js" */),
  "component---src-pages-customer-stories-js": () => import("./../../../src/pages/customer-stories.js" /* webpackChunkName: "component---src-pages-customer-stories-js" */),
  "component---src-pages-dont-let-space-go-to-waste-js": () => import("./../../../src/pages/dont-let-space-go-to-waste.js" /* webpackChunkName: "component---src-pages-dont-let-space-go-to-waste-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-forgotpassword-js": () => import("./../../../src/pages/forgotpassword.js" /* webpackChunkName: "component---src-pages-forgotpassword-js" */),
  "component---src-pages-get-the-app-js": () => import("./../../../src/pages/get-the-app.js" /* webpackChunkName: "component---src-pages-get-the-app-js" */),
  "component---src-pages-gofloaters-community-assistance-program-js": () => import("./../../../src/pages/gofloaters-community-assistance-program.js" /* webpackChunkName: "component---src-pages-gofloaters-community-assistance-program-js" */),
  "component---src-pages-how-are-we-different-js": () => import("./../../../src/pages/how-are-we-different.js" /* webpackChunkName: "component---src-pages-how-are-we-different-js" */),
  "component---src-pages-hybrid-js": () => import("./../../../src/pages/hybrid.js" /* webpackChunkName: "component---src-pages-hybrid-js" */),
  "component---src-pages-hybrid-report-js": () => import("./../../../src/pages/hybrid-report.js" /* webpackChunkName: "component---src-pages-hybrid-report-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-meeting-rooms-js": () => import("./../../../src/pages/meeting-rooms.js" /* webpackChunkName: "component---src-pages-meeting-rooms-js" */),
  "component---src-pages-meeting-spaces-in-bangalore-js": () => import("./../../../src/pages/meeting-spaces-in-bangalore.js" /* webpackChunkName: "component---src-pages-meeting-spaces-in-bangalore-js" */),
  "component---src-pages-meeting-spaces-js": () => import("./../../../src/pages/meeting-spaces.js" /* webpackChunkName: "component---src-pages-meeting-spaces-js" */),
  "component---src-pages-monthly-office-js": () => import("./../../../src/pages/monthly-office.js" /* webpackChunkName: "component---src-pages-monthly-office-js" */),
  "component---src-pages-my-profile-js": () => import("./../../../src/pages/my-profile.js" /* webpackChunkName: "component---src-pages-my-profile-js" */),
  "component---src-pages-office-spaces-for-rent-in-bangalore-js": () => import("./../../../src/pages/office-spaces-for-rent-in-bangalore.js" /* webpackChunkName: "component---src-pages-office-spaces-for-rent-in-bangalore-js" */),
  "component---src-pages-office-spaces-js": () => import("./../../../src/pages/office-spaces.js" /* webpackChunkName: "component---src-pages-office-spaces-js" */),
  "component---src-pages-our-community-partners-js": () => import("./../../../src/pages/our-community-partners.js" /* webpackChunkName: "component---src-pages-our-community-partners-js" */),
  "component---src-pages-our-community-perks-js": () => import("./../../../src/pages/our-community-perks.js" /* webpackChunkName: "component---src-pages-our-community-perks-js" */),
  "component---src-pages-passes-js": () => import("./../../../src/pages/passes.js" /* webpackChunkName: "component---src-pages-passes-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-private-listyourspace-js": () => import("./../../../src/pages/private/listyourspace.js" /* webpackChunkName: "component---src-pages-private-listyourspace-js" */),
  "component---src-pages-recently-added-coworking-spaces-js": () => import("./../../../src/pages/recently-added/coworking-spaces.js" /* webpackChunkName: "component---src-pages-recently-added-coworking-spaces-js" */),
  "component---src-pages-recently-added-meeting-spaces-js": () => import("./../../../src/pages/recently-added/meeting-spaces.js" /* webpackChunkName: "component---src-pages-recently-added-meeting-spaces-js" */),
  "component---src-pages-recently-added-office-spaces-js": () => import("./../../../src/pages/recently-added/office-spaces.js" /* webpackChunkName: "component---src-pages-recently-added-office-spaces-js" */),
  "component---src-pages-remote-working-tips-to-be-stay-happy-and-productive-working-remotely-js": () => import("./../../../src/pages/remote-working/tips-to-be-stay-happy-and-productive-working-remotely.js" /* webpackChunkName: "component---src-pages-remote-working-tips-to-be-stay-happy-and-productive-working-remotely-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-searchresult-js": () => import("./../../../src/pages/searchresult.js" /* webpackChunkName: "component---src-pages-searchresult-js" */),
  "component---src-pages-shared-office-js": () => import("./../../../src/pages/shared-office.js" /* webpackChunkName: "component---src-pages-shared-office-js" */),
  "component---src-pages-shared-office-space-js": () => import("./../../../src/pages/shared-office-space.js" /* webpackChunkName: "component---src-pages-shared-office-space-js" */),
  "component---src-pages-sitesearch-js": () => import("./../../../src/pages/sitesearch.js" /* webpackChunkName: "component---src-pages-sitesearch-js" */),
  "component---src-pages-space-suggestions-js": () => import("./../../../src/pages/space-suggestions.js" /* webpackChunkName: "component---src-pages-space-suggestions-js" */),
  "component---src-pages-suggest-space-js": () => import("./../../../src/pages/suggest-space.js" /* webpackChunkName: "component---src-pages-suggest-space-js" */),
  "component---src-pages-suggestthanks-js": () => import("./../../../src/pages/suggestthanks.js" /* webpackChunkName: "component---src-pages-suggestthanks-js" */),
  "component---src-pages-teams-js": () => import("./../../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-teamsearch-js": () => import("./../../../src/pages/teamsearch.js" /* webpackChunkName: "component---src-pages-teamsearch-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-work-cafe-js": () => import("./../../../src/pages/work-cafe.js" /* webpackChunkName: "component---src-pages-work-cafe-js" */),
  "component---src-pages-worqflexi-js": () => import("./../../../src/pages/worqflexi.js" /* webpackChunkName: "component---src-pages-worqflexi-js" */),
  "component---src-templates-city-pages-js": () => import("./../../../src/templates/city-pages.js" /* webpackChunkName: "component---src-templates-city-pages-js" */),
  "component---src-templates-coworking-new-js": () => import("./../../../src/templates/coworking-new.js" /* webpackChunkName: "component---src-templates-coworking-new-js" */),
  "component---src-templates-coworking-spaces-city-js": () => import("./../../../src/templates/coworking-spaces-city.js" /* webpackChunkName: "component---src-templates-coworking-spaces-city-js" */),
  "component---src-templates-coworking-spaces-locality-all-js": () => import("./../../../src/templates/coworking-spaces-locality-all.js" /* webpackChunkName: "component---src-templates-coworking-spaces-locality-all-js" */),
  "component---src-templates-coworking-spaces-locality-js": () => import("./../../../src/templates/coworking-spaces-locality.js" /* webpackChunkName: "component---src-templates-coworking-spaces-locality-js" */),
  "component---src-templates-meeting-new-js": () => import("./../../../src/templates/meeting-new.js" /* webpackChunkName: "component---src-templates-meeting-new-js" */),
  "component---src-templates-meeting-spaces-city-js": () => import("./../../../src/templates/meeting-spaces-city.js" /* webpackChunkName: "component---src-templates-meeting-spaces-city-js" */),
  "component---src-templates-meeting-spaces-locality-all-js": () => import("./../../../src/templates/meeting-spaces-locality-all.js" /* webpackChunkName: "component---src-templates-meeting-spaces-locality-all-js" */),
  "component---src-templates-meeting-spaces-locality-js": () => import("./../../../src/templates/meeting-spaces-locality.js" /* webpackChunkName: "component---src-templates-meeting-spaces-locality-js" */),
  "component---src-templates-office-js": () => import("./../../../src/templates/office.js" /* webpackChunkName: "component---src-templates-office-js" */),
  "component---src-templates-office-spaces-city-js": () => import("./../../../src/templates/office-spaces-city.js" /* webpackChunkName: "component---src-templates-office-spaces-city-js" */),
  "component---src-templates-office-spaces-locality-all-js": () => import("./../../../src/templates/office-spaces-locality-all.js" /* webpackChunkName: "component---src-templates-office-spaces-locality-all-js" */),
  "component---src-templates-office-spaces-locality-js": () => import("./../../../src/templates/office-spaces-locality.js" /* webpackChunkName: "component---src-templates-office-spaces-locality-js" */)
}

