export const SPACE_BOOKING_TYPES = {
  HOUR: "Hour",
  DAY: "Day",
}

export const NUMBERS = ["First", "Second", "Third", "Fourth", "Five"]
export const MAX_ALLOWED_BOOKINGS = 5

export const STATUS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
}

export const BOOKING_STAGE_STATUS = {
  COMPLETED: "COMPLETED",
  IN_PROGRESS: "IN_PROGRESS",
  NOT_STARTED: "NOT_STARTED",
}

export const BOOKING_TYPE_VALUES = {
  ACTIVE: "ACTIVE",
  PAST: "PAST",
}

export const BOOKING_TYPES = [
  {
    label: "Active",
    value: BOOKING_TYPE_VALUES.ACTIVE,
  },
  { label: "Past Bookings", value: BOOKING_TYPE_VALUES.PAST },
]

export const VIEWS = {
  LOGIN: "LOGIN",
  SIGNUP: "SIGNUP",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  VERIFY_EMAIL: "VERIFY_EMAIL",
}
