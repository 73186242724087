import { initializeApp } from "firebase/app"
import "firebase/auth"
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database"

const config = {
  apiKey: "AIzaSyBCJXQI-JcKPAfG0ONO_x2D2yo_INuzV7c",
  authDomain: "gofloaters.firebaseapp.com",
  databaseURL: "https://gofloaters.firebaseio.com",
  projectId: "gofloaters",
  storageBucket: "gofloaters.appspot.com",
  messagingSenderId: "187167465849",
  appId: "1:187167465849:web:425043bfad6fcb46910fa8",
  measurementId: "G-2KFVB0CQSV",
}

export const firebase = initializeApp(config)
export const firebaseAuth = getAuth(firebase)
export const database = getDatabase(firebase)
